'use client';

import { useAtomValue, useSetAtom } from 'jotai';
import { ReactElement, memo, useEffect, useRef, useState } from 'react';

import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { tenantAtom } from '@core/Atoms/Tenant/Tenant.atom';
import { useIntersectionObserver } from '@core/Hooks/UseIntersectionObserver';
import { clientLog } from '@core/Utils/Log/ClientLog';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';

import { adSlotAtom } from '../../Atoms/AdSlot.atom';
import { showAdAtom } from '../../Atoms/InitAds.atom';
import { BLOCKER_FALLBACK_TIMEOUT, FALLBACK_TIMEOUT } from '../../Constants/Constants';
import { targetingSites } from '../../Constants/TargetingSites';
import { AdUnitId, adUnits } from '../../Constants/Units';
import { AdUnit, Targeting } from '../../Entities/AdUnit.entity';

import styles from './Ad.module.css';
import { isMobileAppAtom } from '@mobile-app/Atoms/IsMobileApp.atom';

export type AdProps = {
  unitId: AdUnitId;
  shouldShowInitially?: boolean;
  withMargin?: boolean;
  stickyPadding?: number;
  stickyPaddingOffset?: number;
  targeting?: Targeting;
  FallbackComponent?: ReactElement;
  displayNoneWhenNoAd?: boolean;
};

export const Ad = memo((props: AdProps) => {
  const {
    unitId,
    stickyPadding,
    stickyPaddingOffset,
    withMargin,
    targeting,
    FallbackComponent,
    displayNoneWhenNoAd,
  } = props;
  const adUnit: AdUnit = adUnits[unitId];

  if (!adUnit) return null;

  const defaultHeaderOffset = 122;
  const locale = useAtomValue(localeAtom);
  const showAd = useSetAtom(showAdAtom);
  const flags = useAtomValue(flagsAtom);
  const isMobileApp = useAtomValue(isMobileAppAtom);
  const [isVisible, setIsVisible] = useState(false);
  const [showFB, setShowFB] = useState(false);
  const $ad = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);
  const { slot, isViewable, isInitialised, renderedHeight } = useAtomValue(adSlotAtom(unitId));
  const tenant = useAtomValue(tenantAtom);

  const fullTargeting = {
    ...targeting,
    consumer: isMobileApp ? 'mobile app' : undefined,
    site: targetingSites[tenant || locale],
    hc_env: process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 'production' : 'development',
  };

  const { minWidth = 0, maxWidth = 99999 } = adUnit;

  // init Ad
  useEffect(() => {
    if (!adUnit || !isFirstRender.current || !$ad.current) return;
    isFirstRender.current = false;
    showAd(unitId, fullTargeting);
  }, [adUnit, $ad.current]);

  // we need to show the fallback after a timeout to stop it flashing up before the live ad does
  useEffect(() => {
    let fBTimeout: string | number | NodeJS.Timeout | undefined;

    if (!slot) {
      if (fBTimeout) clearTimeout(fBTimeout);
      fBTimeout = setTimeout(() => {
        setShowFB(true);
      }, BLOCKER_FALLBACK_TIMEOUT);
    }
    if (slot && !isViewable) {
      if (fBTimeout) clearTimeout(fBTimeout);
      fBTimeout = setTimeout(() => {
        setShowFB(true);
      }, FALLBACK_TIMEOUT);
    }
    if (slot && isViewable) {
      clearTimeout(fBTimeout);
      setShowFB(false);
    }

    return () => clearTimeout(fBTimeout);
  }, [slot, isViewable]);

  useIntersectionObserver($ad, entry => {
    setIsVisible(isVisible => isVisible || entry.isIntersecting);

    if (entry.isIntersecting) {
      window.googletag.cmd.push(function () {
        window.googletag.display(unitId);
      });
    }
  });

  if (!adUnit || !flags.ads) {
    clientLog({
      label: `Ad component not rendered ${unitId}`,
      data: {
        adUnit,
        slot,
        isInitialised,
        isViewable,
        responseInfo: slot?.getResponseInformation?.(),
        targetting: slot?.getTargetingKeys?.(),
        flag: flags.ads,
      },
    });
    return null;
  }

  return (
    <div
      className={styles.wrapper}
      ref={$ad}
      data-with-margin={withMargin}
      data-min-width={minWidth}
      data-max-width={maxWidth}
      data-is-visible={isVisible}
      data-is-viewable={isViewable}
      data-height={adUnit.defaultHeight}
      data-rendered-height={renderedHeight}
      data-show-fallback={showFB}
      data-ad
      style={
        {
          display: showFB && displayNoneWhenNoAd ? 'none' : 'block',
          '--renderedHeight': renderedHeight,
          '--stickyPadding': renderedHeight ? stickyPadding ?? 0 : 0,
          '--stickyPaddingOffset': renderedHeight ? stickyPaddingOffset ?? defaultHeaderOffset : 0,
          //fixes and issue where the divs of empty slots overflow this container and block any buttons etc sitting below
          overflow: slot ? 'visible' : 'hidden',
        } as React.CSSProperties
      }
    >
      {showFB && FallbackComponent}
      <div className={styles.ad} data-ad-unit-id={unitId} ref={$ad}>
        <div
          id={unitId}
          data-unit-id={unitId}
          style={{ width: '100%' }}
          data-show-fallback={showFB}
          className={!isViewable ? styles.adsNotVisible : ''}
        ></div>
      </div>
    </div>
  );
});

Ad.displayName = 'Ad';
